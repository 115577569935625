import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useMeasure, useKeyPressEvent } from "react-use";

// Slideshow
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Utils
import kebabCase from "lodash.kebabcase";

// Icons
import { Toggle } from "../icons/menu-toggle";

// Layout
import {
  Header,
  GalleryContainer,
  ImageContainer,
  Slide,
  Arrow,
  PageLink,
} from "./project-desktop-components";

export const ProjectGalleryDesktop = ({
  data,
  location,
  pageContext,
  totalMainSlides,
  setTotalMainSlides,
  isPasswordProtected,
  checkPassword,
  password,
  userPassword,
  setUserPassword,
  isPasswordCorrect,
}) => {
  // Slideshow Reference
  const slideRef = useRef();

  // Keeping track of slides
  const [previousSlideNumber, setPreviousNumber] = useState(0);
  const [nextSlideNumber, setNextSlideNumber] = useState(0);

  // Project Header Opacity
  const [headerOpacity, setHeaderOpacity] = useState(0);
  const checkHeaderOpacity = () => {
    if (slideRef.current !== undefined && slideRef.current.state.index === 0) {
      setHeaderOpacity(0);
    } else {
      setHeaderOpacity(1);
    }
  };

  // If coming from previous project, go to last slide
  useEffect(() => {
    if (
      location !== undefined &&
      location !== null &&
      location.state !== undefined &&
      location.state !== null &&
      location.state.lastSlide !== undefined &&
      location.state.lastSlide !== null
    ) {
      if (slideRef.current !== undefined) {
        if (location.state.lastSlide === true) {
          if (isPasswordProtected) {
            slideRef.current.goTo(totalMainSlides);
          } else {
            slideRef.current.goTo(totalMainSlides - 1);
          }
        }
      }
    }
  }, [location]);

  const slideOrder = data.prismicProject.data.body.map(
    slide => slide.slice_type
  );

  const firstSingleImageId = data.prismicProject.data.body
    .filter(content => content.slice_type === "single_image")
    .filter(content => content.primary.image.fluid !== null)
    .map(content => content.id)[0];

  // Keyboard navigation events for slideshow
  const goBack = () => {
    if (slideRef.current !== undefined) {
      slideRef.current.goBack();
    }
  };

  const goNext = () => {
    if (slideRef.current !== undefined) {
      slideRef.current.goNext();
    }
  };
  useKeyPressEvent("ArrowRight", goNext);
  useKeyPressEvent("ArrowLeft", goBack);

  // Slideshow autoplay
  const [autoplay, setAutoplay] = useState(true);

  // Measure dimensions of first single image
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  const galleryContent = data.prismicProject.data.body.map((content, index) => {
    if (content.slice_type === "title") {
      return (
        <Slide
          className="title"
          key={`single_project_title_container_${content.id}_${index}`}
          onMouseEnter={() => setAutoplay(false)}
          onMouseLeave={() => setAutoplay(true)}
        >
          <div
            className={`uppercase center-text soehne-extra-light font-render`}
            dangerouslySetInnerHTML={{
              __html: content.primary.title_slide.html,
            }}
          />
        </Slide>
      );
    }

    if (content.slice_type === "text") {
      return (
        <Slide
          className="text max-width"
          key={`single_project_text_container_${content.id}_${index}`}
          onMouseEnter={() => setAutoplay(false)}
          onMouseLeave={() => setAutoplay(true)}
        >
          <div
            className="text-slide"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </Slide>
      );
    }

    if (content.slice_type === "single_image") {
      return (
        <Slide
          className="image-slide max-width"
          key={`single_project_image_container_${content.id}_${index}`}
          onMouseEnter={() => setAutoplay(false)}
          onMouseLeave={() => setAutoplay(true)}
        >
          <div className="aspect-ratio-box">
            <div className="aspect-ratio-box-inside">
              {firstSingleImageId === content.id ? (
                <ImageContainer className="single-image" ref={ref}>
                  {content.primary.image.fluid !== null && (
                    <img
                      srcSet={content.primary.image.fluid.srcSetWebp}
                      src={content.primary.image.fluid.srcWebp}
                      alt={content.primary.image.alt}
                      loading={`lazy`}
                    />
                  )}
                </ImageContainer>
              ) : (
                <ImageContainer className="single-image">
                  {content.primary.image.fluid !== null && (
                    <img
                      srcSet={content.primary.image.fluid.srcSetWebp}
                      src={content.primary.image.fluid.srcWebp}
                      alt={content.primary.image.alt}
                      loading={`lazy`}
                    />
                  )}
                </ImageContainer>
              )}
            </div>
          </div>
        </Slide>
      );
    }

    if (content.slice_type === "two_images") {
      return (
        <Slide
          className={`max-width image-slide two-images-container`}
          key={`single_project_image_container_${content.id}_${index}`}
          width={width}
          height={height}
          onMouseEnter={() => setAutoplay(false)}
          onMouseLeave={() => setAutoplay(true)}
        >
          <div className="aspect-ratio-box">
            <div
              className={`aspect-ratio-box-inside two-images small-${kebabCase(
                content.primary.small_image
              )}`}
            >
              <ImageContainer className="image-left">
                <div className="image-container">
                  {content.primary.image_left.fluid !== null && (
                    <img
                      srcSet={content.primary.image_left.fluid.srcSetWebp}
                      src={content.primary.image_left.fluid.srcWebp}
                      alt={content.primary.image_left.alt}
                      loading={`lazy`}
                    />
                  )}

                  {content.primary.small_image === "Image Left" && (
                    <div className="image-captions">
                      <div className="image-caption image-left-caption">
                        <p className="uppercase caption-layout soehne-buch">
                          Above
                        </p>
                        <div
                          className="caption-text"
                          dangerouslySetInnerHTML={{
                            __html: content.primary.image_left_caption.html,
                          }}
                        />
                      </div>
                      <div className="image-caption image-right-caption">
                        <p className="uppercase caption-layout soehne-buch">
                          Right
                        </p>
                        <div
                          className="caption-text"
                          dangerouslySetInnerHTML={{
                            __html: content.primary.image_right_caption.html,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </ImageContainer>

              <div className="spacer" />

              <ImageContainer className="image-right">
                <div className="image-container">
                  {content.primary.image_right.fluid !== null && (
                    <img
                      srcSet={content.primary.image_right.fluid.srcSetWebp}
                      src={content.primary.image_right.fluid.srcWebp}
                      alt={content.primary.image_right.alt}
                      loading={`lazy`}
                    />
                  )}

                  {content.primary.small_image === "Image Right" && (
                    <div className="image-captions">
                      <div className="image-caption image-left-caption">
                        <p className="uppercase caption-layout soehne-buch">
                          Left
                        </p>
                        <div
                          className="caption-text"
                          dangerouslySetInnerHTML={{
                            __html: content.primary.image_left_caption.html,
                          }}
                        />
                      </div>
                      <div className="image-caption image-right-caption">
                        <p className="uppercase caption-layout soehne-buch">
                          Above
                        </p>
                        <div
                          className="caption-text"
                          dangerouslySetInnerHTML={{
                            __html: content.primary.image_right_caption.html,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </ImageContainer>
            </div>
          </div>
        </Slide>
      );
    }
  });

  const passwordProtectSlide = [
    <Slide
      className="password-entry-slide max-width"
      key={`password_protected_input_form`}
      onMouseEnter={() => setAutoplay(false)}
      onMouseLeave={() => setAutoplay(true)}
    >
      <div className="password-container">
        <form
          className="password-protected-form"
          name="password-protected-form"
          onSubmit={event => checkPassword(event)}
        >
          <input
            type="text"
            placeholder={
              userPassword === ``
                ? `Enter Password`
                : isPasswordCorrect === true
                ? ``
                : `Incorrect Password`
            }
            onChange={event => setUserPassword(event.target.value)}
            value={userPassword}
          />

          <button
            type="submit"
            onClick={event => checkPassword(event)}
            className={userPassword !== `` ? `visible` : `hidden`}
          >
            Enter
          </button>
        </form>
      </div>
    </Slide>,
  ];

  let finalGalleryContent = galleryContent;

  if (isPasswordProtected) {
    setTotalMainSlides(galleryContent.length + 1);
    finalGalleryContent = galleryContent.concat(passwordProtectSlide);
  } else {
    setTotalMainSlides(galleryContent.length);
  }

  return (
    <>
      <Header opacity={headerOpacity}>
        <div className="title">
          <button onClick={() => slideRef.current.goTo(0)}>
            <h1 className="soehne-light">
              {data.prismicProject.data.title.text}
            </h1>
          </button>
        </div>

        <div
          className="tagline"
          dangerouslySetInnerHTML={{
            __html: data.prismicProject.data.tagline.html,
          }}
        />

        <div className="back-button">
          <Link to={`/projects`} aria-label="Visit Projects">
            <Toggle
              isOpen={true}
              fill={
                slideRef.current !== undefined &&
                slideRef.current.state.index === 0
                  ? `#F8F1E8`
                  : `#000`
              }
            />
          </Link>
        </div>
      </Header>

      <GalleryContainer>
        <Fade
          ref={slideRef}
          autoplay={autoplay}
          pauseOnHover={false}
          duration={5000}
          transitionDuration={1000}
          easing={`ease`}
          infinite={false}
          canSwipe={true}
          onChange={(previous, next) => {
            setNextSlideNumber(next);
            setPreviousNumber(previous);
            checkHeaderOpacity();
          }}
          arrows={false}
        >
          {finalGalleryContent}
        </Fade>

        {slideRef.current !== undefined && slideRef.current.state.index !== 0 && (
          <Arrow
            aria-label="Previous Slide"
            className={`previous ${
              slideRef.current.state.index === 0 ? `title-slide` : ``
            }`}
            onClick={() => {
              if (
                slideRef.current !== undefined &&
                slideRef.current.state.index === 1
              ) {
                setHeaderOpacity(0);
              }
              slideRef.current.goBack();
            }}
            color={slideOrder[nextSlideNumber] === "title" ? `light` : `dark`}
          />
        )}

        {slideRef.current !== undefined &&
          nextSlideNumber + 1 !== totalMainSlides && (
            <Arrow
              aria-label="Next Slide"
              className={`next ${
                slideRef.current.state.index === 0 ? `title-slide` : ``
              }`}
              onClick={() => {
                if (
                  slideRef.current !== undefined &&
                  slideRef.current.state.index === 0
                ) {
                  setHeaderOpacity(1);
                }
                slideRef.current.goNext();
              }}
              color={slideOrder[nextSlideNumber] === "title" ? `light` : `dark`}
            />
          )}

        {pageContext.previousProjectUid !== null &&
          slideRef.current !== undefined &&
          slideRef.current.state.index === 0 && (
            <PageLink color={`light`} className="previous">
              <Link
                to={`/projects/${pageContext.previousProjectUid}`}
                aria-label={`Visit ${pageContext.previousProjectTitle}`}
                state={{ lastSlide: true }}
              />
            </PageLink>
          )}

        {pageContext.nextProjectUid !== null &&
          nextSlideNumber + 1 === totalMainSlides && (
            <PageLink color={`dark`} className="next">
              <Link
                to={`/projects/${pageContext.nextProjectUid}`}
                aria-label={`Visit ${pageContext.nextProjectTitle}`}
              />
            </PageLink>
          )}
      </GalleryContainer>
    </>
  );
};
