import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Div100vh from "react-div-100vh";
import { useMeasure, useKeyPressEvent } from "react-use";

// Components
import { ProjectGalleryDesktop } from "./project-gallery-desktop";
import { PasswordProtectedGalleryDesktop } from "./password-protected-gallery-desktop";

// Context
import { PageType } from "../context/page-type";
import { PageBackgroundColor } from "../context/page-background-color";

const Page = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;
`;

export const ProjectDesktop = ({
  data,
  pageContext,
  location,
  password,
  userPassword,
  setUserPassword,
  isPasswordCorrect,
  isPasswordProtected,
  checkPassword,
}) => {
  const [totalMainSlides, setTotalMainSlides] = useState(
    data.prismicProject.data.body.length
  );

  // Page Type
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );

  useEffect(() => {
    setPageBackgroundColor(`#FFFDFA`);
  }, []);

  return (
    <Div100vh>
      <Page>
        {isPasswordProtected ? (
          <>
            {isPasswordProtected && isPasswordCorrect ? (
              <PasswordProtectedGalleryDesktop
                data={data}
                location={location}
                pageContext={pageContext}
                totalMainSlides={totalMainSlides}
                setTotalMainSlides={setTotalMainSlides}
                isPasswordProtected={isPasswordProtected}
                password={password}
                userPassword={userPassword}
                setUserPassword={setUserPassword}
                isPasswordCorrect={isPasswordCorrect}
                checkPassword={checkPassword}
              />
            ) : (
              <ProjectGalleryDesktop
                data={data}
                location={location}
                pageContext={pageContext}
                totalMainSlides={totalMainSlides}
                setTotalMainSlides={setTotalMainSlides}
                isPasswordProtected={isPasswordProtected}
                password={password}
                userPassword={userPassword}
                setUserPassword={setUserPassword}
                isPasswordCorrect={isPasswordCorrect}
                checkPassword={checkPassword}
              />
            )}
          </>
        ) : (
          <>
            <ProjectGalleryDesktop
              data={data}
              location={location}
              pageContext={pageContext}
              totalMainSlides={totalMainSlides}
              setTotalMainSlides={setTotalMainSlides}
              isPasswordProtected={isPasswordProtected}
              password={password}
              userPassword={userPassword}
              setUserPassword={setUserPassword}
              isPasswordCorrect={isPasswordCorrect}
              checkPassword={checkPassword}
            />
          </>
        )}
      </Page>
    </Div100vh>
  );
};
