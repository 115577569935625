import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "gatsby";

import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// Context
import { PageType } from "../context/page-type";
import { PageBackgroundColor } from "../context/page-background-color";

// Utils
import kebabCase from "lodash.kebabcase";

// Icons
import { Toggle } from "../icons/menu-toggle";
import { MobileProjectArrow } from "../icons/arrows";

// Components
import { PasswordProtectedGalleryMobile } from "./password-protected-gallery-mobile";

const Page = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

const TopImage = styled.div`
  height: calc(${props => props.height}px - 56px);
  overflow: hidden;

  & img {
    height: 100%;
    object-fit: cover;
  }
`;

const CloseProjectContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;

  height: 56px;

  padding: 0 30px;

  background-color: #f8f1e8;
  z-index: 100;

  & a {
    display: flex;
    align-items: center;
  }
`;

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  padding: 0;

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;
  }
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  & img {
    object-fit: contain;
  }

  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const Slide = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  margin: 0 0 70px 0;

  &.title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding: 130px 20px;
    margin: 0;

    & .title-slide {
      color: #000;
      text-align: center;

      & h1 {
        font-size: 30px;
        line-height: 44px;
        letter-spacing: 0.15em;

        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
        text-rendering: optimizeLegibility !important;
        -webkit-font-smoothing: antialiased !important;
      }
    }
  }

  &.text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding: 0 30px;

    & .text-slide {
      max-width: 800px;
      margin: 0 auto;

      & p:first-of-type {
        margin-top: 0;
      }

      & p:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &.image-slide {
    height: 100%;
  }

  &.two-images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    & .image-container {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &.small-image-right {
      & .image-left {
        grid-column: 1 / 6;
        padding: 0 0 70px 0;
      }

      & .image-right {
        grid-column: 2 / 6;
        padding: 0 15px 0 0;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    &.small-image-left {
      & .image-left {
        grid-column: 1 / 5;
        padding: 0 0 70px 15px;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      & .image-right {
        grid-column: 1 / 6;
      }
    }
  }

  & .image-left {
    & img {
      object-position: left;
    }
  }

  & .image-right {
    & .image-captions {
      margin: 0 0 0 auto;
    }

    & img {
      object-position: right;
    }
  }

  & .image-captions {
    width: 100%;
    max-width: 350px;

    align-self: flex-end;

    & .image-caption {
      display: grid;
      grid-template-columns: 40px auto;
      grid-column-gap: 30px;

      & p {
        margin: 0;
      }

      & .caption-layout {
        font-size: 8px;
        line-height: 18px;
        letter-spacing: 0.15em;
      }

      & .caption-text {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.02em;
      }
    }
  }
`;

const NextPageLink = styled.div`
  padding: 20px 30px 130px 30px;
  margin: 0;

  & a {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    color: #000;
    text-align: center;

    & svg {
      margin: 0 0 10px 0;
      display: block;
    }

    & h1 {
      font-size: 18px;
      line-height: 36px;
      letter-spacing: 0.15em;

      width: 100%;
      display: block;
    }
  }
`;

export const ProjectMobile = ({
  data,
  pageContext,
  password,
  userPassword,
  setUserPassword,
  isPasswordCorrect,
  isPasswordProtected,
  checkPassword,
}) => {
  const height = use100vh();

  const [totalSlides, setTotalSlides] = useState(
    data.prismicProject.data.body.length
  );
  const [nextSlideNumber, setNextSlideNumber] = useState(0);

  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );

  useEffect(() => {
    setPageBackgroundColor(`#F8F1E8`);
  }, [data]);

  const galleryContent = data.prismicProject.data.body.map((content, index) => {
    if (content.slice_type === "title") {
      return (
        <Slide
          className="title"
          key={`single_project_title_container_${content.id}_${index}`}
        >
          <div
            className="title-slide soehne-extra-light uppercase"
            dangerouslySetInnerHTML={{
              __html: data.prismicProject.data.title.html,
            }}
          />
        </Slide>
      );
    }

    if (content.slice_type === "text") {
      return (
        <Slide
          className="text"
          key={`single_project_text_container_${content.id}_${index}`}
        >
          <div
            className="text-slide"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </Slide>
      );
    }

    if (content.slice_type === "single_image") {
      return (
        <Slide
          key={`single_project_image_container_${content.id}_${index}`}
          className="image-slide"
        >
          <ImageContainer>
            {content.primary.image.fluid !== null && (
              <img
                srcSet={content.primary.image.fluid.srcSetWebp}
                src={content.primary.image.fluid.srcWebp}
                alt={content.primary.image.alt}
                loading={`lazy`}
              />
            )}
          </ImageContainer>
        </Slide>
      );
    }

    if (content.slice_type === "two_images") {
      return (
        <Slide
          key={`single_project_image_container_${content.id}_${index}`}
          className={`image-slide two-images small-${kebabCase(
            content.primary.small_image
          )}`}
        >
          <ImageContainer className="image-left">
            <div className="image-container">
              {content.primary.image_left.fluid !== null && (
                <img
                  srcSet={content.primary.image_left.fluid.srcSetWebp}
                  src={content.primary.image_left.fluid.srcWebp}
                  alt={content.primary.image_left.alt}
                  loading={`lazy`}
                />
              )}
            </div>
          </ImageContainer>

          <ImageContainer className="image-right">
            <div className="image-container">
              {content.primary.image_right.fluid !== null && (
                <img
                  srcSet={content.primary.image_right.fluid.srcSetWebp}
                  src={content.primary.image_right.fluid.srcWebp}
                  alt={content.primary.image_right.alt}
                  loading={`lazy`}
                />
              )}
            </div>
          </ImageContainer>
        </Slide>
      );
    }
  });

  return (
    <Page>
      <GalleryContainer>
        {data.prismicProject.data.top_mobile_image.fluid !== null && (
          <TopImage height={height}>
            <img
              srcSet={
                data.prismicProject.data.top_mobile_image.fluid.srcSetWebp
              }
              src={data.prismicProject.data.top_mobile_image.fluid.srcWebp}
              alt={data.prismicProject.data.top_mobile_image.alt}
              loading={`lazy`}
            />
          </TopImage>
        )}

        <CloseProjectContainer>
          <Link to={`/projects`} aria-label="Visit Projects">
            <Toggle fill={`#000`} isOpen={true} />
          </Link>
        </CloseProjectContainer>

        {galleryContent}

        {isPasswordProtected && (
          <PasswordProtectedGalleryMobile
            data={data}
            password={password}
            userPassword={userPassword}
            setUserPassword={setUserPassword}
            isPasswordCorrect={isPasswordCorrect}
            isPasswordProtected={isPasswordProtected}
            checkPassword={checkPassword}
          />
        )}

        {pageContext.nextProjectUid !== null && (
          <NextPageLink>
            <Link to={`/projects/${pageContext.nextProjectUid}`}>
              <MobileProjectArrow />
              <h1 className="soehne-extra-light uppercase">
                {pageContext.nextProjectTitle}
              </h1>
            </Link>
          </NextPageLink>
        )}
      </GalleryContainer>
    </Page>
  );
};
