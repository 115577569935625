import React from "react";
import styled from "styled-components";

// Utils
import kebabCase from "lodash.kebabcase";

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  & img {
    object-fit: contain;
  }

  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const Slide = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  margin: 0 0 70px 0;

  &.password-entry-slide {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding: 0 30px;

    & .password-container {
      max-width: 320px;
      width: 100%;

      & .password-protected-form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        padding: 0 0 2px 0;
        border-bottom: 1px solid #000;
      }

      @media (max-width: 500px) {
        max-width: 100%;
      }
    }

    & input {
      color: #000;
      padding: 0;

      font-size: 15px;
      line-height: 25px;

      width: calc(100% - 55px);

      ::-webkit-input-placeholder {
        color: #000 !important;

        font-size: 15px !important;
        line-height: 25px !important;
      }

      ::-moz-placeholder {
        color: #000 !important;

        font-size: 15px !important;
        line-height: 25px !important;
      }

      :-ms-input-placeholder {
        color: #000 !important;

        font-size: 15px !important;
        line-height: 25px !important;
      }

      :-moz-placeholder {
        color: #000 !important;

        font-size: 15px !important;
        line-height: 25px !important;
      }
    }

    & button {
      cursor: pointer;
      transition: 250ms opacity ease;

      &.visible {
        opacity: 1;
        pointer-events: all;
      }

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &.title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding: 130px 20px;
    margin: 0;

    & .title-slide {
      color: #000;
      text-align: center;

      & h1 {
        font-size: 30px;
        line-height: 44px;
        letter-spacing: 0.15em;

        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
        text-rendering: optimizeLegibility !important;
        -webkit-font-smoothing: antialiased !important;
      }
    }
  }

  &.text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding: 0 30px;

    & .text-slide {
      max-width: 800px;
      margin: 0 auto;

      & p:first-of-type {
        margin-top: 0;
      }

      & p:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &.image-slide {
    height: 100%;
  }

  &.two-images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    & .image-container {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &.small-image-right {
      & .image-left {
        grid-column: 1 / 6;
        padding: 0 0 70px 0;
      }

      & .image-right {
        grid-column: 2 / 6;
        padding: 0 15px 0 0;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    &.small-image-left {
      & .image-left {
        grid-column: 1 / 5;
        padding: 0 0 70px 15px;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      & .image-right {
        grid-column: 1 / 6;
      }
    }
  }

  & .image-left {
    & img {
      object-position: left;
    }
  }

  & .image-right {
    & .image-captions {
      margin: 0 0 0 auto;
    }

    & img {
      object-position: right;
    }
  }

  & .image-captions {
    width: 100%;
    max-width: 350px;

    align-self: flex-end;

    & .image-caption {
      display: grid;
      grid-template-columns: 40px auto;
      grid-column-gap: 30px;

      & p {
        margin: 0;
      }

      & .caption-layout {
        font-size: 8px;
        line-height: 18px;
        letter-spacing: 0.15em;
      }

      & .caption-text {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.02em;
      }
    }
  }
`;

export const PasswordProtectedGalleryMobile = ({
  data,
  password,
  userPassword,
  setUserPassword,
  isPasswordCorrect,
  isPasswordProtected,
  checkPassword,
}) => {
  const passwordProtectedContent = data.prismicProject.data.body1.map(
    (content, index) => {
      if (content.slice_type === "title") {
        return (
          <Slide
            className="title"
            key={`single_project_title_container_${content.id}_${index}`}
          >
            <div
              className="title-slide soehne-extra-light uppercase"
              dangerouslySetInnerHTML={{
                __html: data.prismicProject.data.title.html,
              }}
            />
          </Slide>
        );
      }

      if (content.slice_type === "text") {
        return (
          <Slide
            className="text"
            key={`single_project_text_container_${content.id}_${index}`}
          >
            <div
              className="text-slide"
              dangerouslySetInnerHTML={{
                __html: content.primary.text.html,
              }}
            />
          </Slide>
        );
      }

      if (content.slice_type === "single_image") {
        return (
          <Slide
            key={`single_project_image_container_${content.id}_${index}`}
            className="image-slide"
          >
            <ImageContainer>
              {content.primary.image.fluid !== null && (
                <img
                  srcSet={content.primary.image.fluid.srcSetWebp}
                  src={content.primary.image.fluid.srcWebp}
                  alt={content.primary.image.alt}
                  loading={`lazy`}
                />
              )}
            </ImageContainer>
          </Slide>
        );
      }

      if (content.slice_type === "two_images") {
        return (
          <Slide
            key={`single_project_image_container_${content.id}_${index}`}
            className={`image-slide two-images small-${kebabCase(
              content.primary.small_image
            )}`}
          >
            <ImageContainer className="image-left">
              <div className="image-container">
                {content.primary.image_left.fluid !== null && (
                  <img
                    srcSet={content.primary.image_left.fluid.srcSetWebp}
                    src={content.primary.image_left.fluid.srcWebp}
                    alt={content.primary.image_left.alt}
                    loading={`lazy`}
                  />
                )}
              </div>
            </ImageContainer>

            <ImageContainer className="image-right">
              <div className="image-container">
                {content.primary.image_right.fluid !== null && (
                  <img
                    srcSet={content.primary.image_right.fluid.srcSetWebp}
                    src={content.primary.image_right.fluid.srcWebp}
                    alt={content.primary.image_right.alt}
                    loading={`lazy`}
                  />
                )}
              </div>
            </ImageContainer>
          </Slide>
        );
      }
    }
  );

  return (
    <>
      {!isPasswordCorrect ? (
        <Slide className="password-entry-slide max-width">
          <div className="password-container">
            <form
              className="password-protected-form"
              name="password-protected-form"
              onSubmit={event => checkPassword(event)}
            >
              <input
                type="text"
                placeholder={
                  userPassword === ``
                    ? `Enter Password`
                    : isPasswordCorrect === true
                    ? ``
                    : `Incorrect Password`
                }
                onChange={event => setUserPassword(event.target.value)}
                value={userPassword}
              />

              <button
                type="submit"
                onClick={event => checkPassword(event)}
                className={userPassword !== `` ? `visible` : `hidden`}
              >
                Enter
              </button>
            </form>
          </div>
        </Slide>
      ) : (
        <>{passwordProtectedContent}</>
      )}
    </>
  );
};
