import React from "react";
import styled from "styled-components";

export const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  & img {
    object-fit: contain;
  }

  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &.single-image {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;

    top: 50%;
    transform: translateY(-50%);

    & img {
      max-height: calc(100vh - 100px);
    }
  }
`;

export const Slide = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  padding: 75px 40px;

  cursor: url("/icons/pause@1x.png") 50 0, pointer;
  cursor: -webkit-image-set(
        url("/icons/pause@1x.png") 1x,
        url("/icons/pause@2x.png") 2x
      )
      50 0,
    pointer;
  cursor: url("/icons/pause.svg"), 50 0, pointer;

  &.max-width {
    max-width: 1400px;
    margin: 0 auto;
  }

  &.password-entry-slide {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding: 20px;

    & .password-container {
      max-width: 320px;
      width: 100%;

      & .password-protected-form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        padding: 0 0 2px 0;
        border-bottom: 1px solid #000;
      }
    }

    & input {
      color: #000;

      width: calc(100% - 55px);

      ::-webkit-input-placeholder {
        font-size: 16px !important;
        color: #000 !important;
      }

      ::-moz-placeholder {
        font-size: 16px !important;
        color: #000 !important;
      }

      :-ms-input-placeholder {
        font-size: 16px !important;
        color: #000 !important;
      }

      :-moz-placeholder {
        font-size: 16px !important;
        color: #000 !important;
      }
    }

    & button {
      cursor: pointer;
      transition: 250ms opacity ease;
      font-size: 14px;

      &.visible {
        opacity: 1;
        pointer-events: all;
      }

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  & .aspect-ratio-box {
    height: 100%;

    position: relative;

    max-height: calc(100vh - 100px);

    & .aspect-ratio-box-inside {
      width: 100%;
      height: 100%;

      max-height: calc(100vh - 100px);
    }
  }

  &.title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    background-color: #25272d;

    padding: 80px 40px;

    overflow-y: scroll;

    & h1 {
      font-size: 70px;
      line-height: 95px;
      letter-spacing: 0.15em;

      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
      text-rendering: optimizeLegibility !important;
      -webkit-font-smoothing: antialiased !important;

      color: #f8f1e8;

      @media (max-width: 1450px) {
        font-size: 60px;
        line-height: 80px;
      }

      @media (max-height: 800px) {
        font-size: 50px;
        line-height: 70px;
      }
    }
  }

  &.text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding: 20px;

    & .text-slide {
      max-width: 800px;
      margin: 0 auto;
    }
  }

  &.image-slide {
    height: 100%;
    padding: 70px 40px 30px 40px;
  }

  &.two-images-container {
    & .aspect-ratio-box {
      top: 50%;
      transform: translateY(-50%);

      width: ${props => props.width}px;
      height: ${props => props.height}px;
      margin: 0 auto;
    }
  }

  & .two-images {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;

    & .image-container {
      position: relative;
      width: 100%;
      height: 100%;
    }

    & .spacer {
      flex: 8.333%;
      max-width: 8.333%;
      display: none;
    }

    &.small-image-right {
      & .image-left {
        grid-column: 1 / 7;

        & img {
          object-fit: cover;
        }
      }

      & .image-right {
        grid-column: 8 / 13;

        padding: 75px 0 0 0;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        & .image-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          & img {
            max-height: calc(100% - 100px);
          }
        }
      }
    }

    &.small-image-left {
      & .image-left {
        grid-column: 1 / 6;
        padding: 75px 0 0 0;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;

        & .image-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          & img {
            max-height: calc(100% - 100px);
          }
        }
      }

      & .image-right {
        grid-column: 7 / 13;

        & img {
          object-fit: cover;
        }
      }
    }
  }

  & .image-left {
    & img {
      object-position: left;
    }
  }

  & .image-right {
    & img {
      object-position: right;
    }
  }

  & .image-captions {
    width: 100%;
    max-width: 380px;

    align-self: flex-end;
    margin: 0 0 -6px 0;

    & .image-caption {
      display: grid;
      grid-template-columns: 40px auto;
      grid-column-gap: 30px;
      align-items: baseline;

      & p {
        margin: 0;
      }

      & .caption-layout {
        font-size: 8px;
        line-height: 18px;
        letter-spacing: 0.15em;
      }

      & .caption-text {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.02em;
      }
    }
  }
`;

export const Arrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;

  width: calc(((100vw - 400px) / 2) - 40px);
  min-width: 200px;

  &.title-slide{
    width: calc(100vw - calc(((100vw - 1000px) / 2) - 40px));
  }

  height: 75%;
  z-index: 100;

  &.previous {
    left: 0;

    cursor: url("/icons/arrow-prev-${props =>
      props.color}@1x.png") 50 0, pointer;
    cursor: -webkit-image-set(
          url("/icons/arrow-prev-${props => props.color}@1x.png") 1x,
          url("/icons/arrow-prev-${props => props.color}@2x.png") 2x
        )
        50 0,
      pointer;
    cursor: url("/icons/arrow-prev-${props => props.color}.svg"), 50 0, pointer;
  }

  &.next {
    right: 0;

    cursor: url("/icons/arrow-next-${props =>
      props.color}@1x.png") 50 0, pointer;
    cursor: -webkit-image-set(
          url("/icons/arrow-next-${props => props.color}@1x.png") 1x,
          url("/icons/arrow-next-${props => props.color}@2x.png") 2x
        )
        50 0,
      pointer;
    cursor: url("/icons/arrow-next-${props => props.color}.svg"), 50 0, pointer;
  }

  &.disabled {
    display: none;
  }
`;

export const PageLink = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;

  width: calc(((100vw - 400px) / 2) - 40px);
  min-width: 200px;

  height: 75%;


  z-index: 100;

  &.previous {
    left: 0;

    & a {
      display: block;
      width: 100%;
      height: 100%;

      cursor: url("/icons/arrow-prev-${props =>
        props.color}@1x.png") 50 0, pointer;
      cursor: -webkit-image-set(
        url("/icons/arrow-prev-${props => props.color}@1x.png") 1x,
        url("/icons/arrow-prev-${props => props.color}@2x.png") 2x
      ) 50 0, pointer;
      cursor: url("/icons/arrow-prev-${props =>
        props.color}.svg"), 50 0, pointer;
    }
  }

  &.next {
    right: 0;

    & a {
      display: block;
      width: 100%;
      height: 100%;

      cursor: url("/icons/arrow-next-${props =>
        props.color}@1x.png") 50 0, pointer;
      cursor: -webkit-image-set(
        url("/icons/arrow-next-${props => props.color}@1x.png") 1x,
        url("/icons/arrow-next-${props => props.color}@2x.png") 2x
      ) 50 0, pointer;
      cursor: url("/icons/arrow-next-${props =>
        props.color}.svg"), 50 0, pointer;
    }
  }
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 100;

  padding: 25px 40px;

  & h1 {
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.15em;
    text-transform: uppercase;

    & br {
      display: none;
    }
  }

  & a {
    display: block;

    width: 13px;
    height: 13px;

    margin: 0 0 0 auto;
  }

  & .title,
  & .tagline {
    transition: 300ms opacity ease;
    opacity: ${props => props.opacity};
  }

  & svg {
    transition: 300ms fill ease;
  }

  & .title {
    flex: 1;
  }

  & .back-button {
    flex: 1;

    @media (max-width: 1500px) {
      flex: 0;
    }
  }

  & .tagline {
    flex: 3;
    text-align: center;

    @media (max-width: 1500px) {
      text-align: left;
    }

    & p {
      font-size: 14px;
      line-height: 28px;

      letter-spacing: 0.02em;
      margin: 0;

      @media (max-width: 1300px) {
        font-size: 13px;
      }

      @media (max-width: 1200px) {
        font-size: 12px;
      }
    }
  }
`;

export const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  padding: 0;

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div,
  & .slider-wrapper,
  & .image-gallery,
  & .slider,
  & .slide,
  & video,
  & img {
    height: 100%;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;
  }
`;
