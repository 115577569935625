import React, { useEffect, useContext, useState } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Context
import { PageType } from "../components/context/page-type";

// Icons
import { ProjectDesktop } from "../components/project/project-desktop";
import { ProjectMobile } from "../components/project/project-mobile";

const Project = ({ data, pageContext, location }) => {
  let isDesktop = useMediaQuery("(min-width: 768px)");

  const [pageType, setPageType] = useContext(PageType);

  useEffect(() => {
    setPageType(`hide-nav`);
  }, [data]);

  // Password
  const [password, setPassword] = useState(null);
  const [userPassword, setUserPassword] = useState(``);
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const isPasswordProtected =
    data.prismicProject.data.enable_password_protected_content === true
      ? true
      : false;

  const checkPassword = event => {
    event.preventDefault();

    if (userPassword === password) {
      setIsPasswordCorrect(true);
    } else {
      setUserPassword(``);
      setIsPasswordCorrect(false);
    }
  };

  useEffect(() => {
    if (isPasswordProtected) {
      setPassword(data.prismicProject.data.password);
    }
  }, [isPasswordProtected]);

  return (
    <>
      <Helmet
        title={`${data.prismicProject.data.title.text} | Dan Fink Studio`}
        meta={[
          {
            name: "title",
            content: `${data.prismicProject.data.title.text} | Dan Fink Studio`,
          },
          {
            property: "og:title",
            content: `${data.prismicProject.data.title.text} | Dan Fink Studio`,
          },
          {
            property: "og:image",
            content: `${data.prismicProject.data.image.url}`,
          },
          {
            property: "og:image:secure_url",
            content: `${data.prismicProject.data.image.url}`,
          },
          {
            property: "og:image:width",
            content: `1200`,
          },
          {
            property: "og:image:height",
            content: `630`,
          },
          {
            property: "og:locale",
            content: `en`,
          },
          {
            name: "description",
            content: `${data.prismicProject.data.tagline.text} | Dan Fink Studio
						`,
          },
          {
            property: "og:description",
            content: `${data.prismicProject.data.tagline.text} | Dan Fink Studio
						`,
          },
          {
            property: "twitter:title",
            content: `${data.prismicProject.data.title.text} | Dan Fink Studio`,
          },
          {
            property: "twitter:description",
            content: `${data.prismicProject.data.tagline.text} | Dan Fink Studio
						`,
          },
          {
            property: "twitter:card",
            content: `summary_large_image`,
          },
          {
            property: "twitter:image",
            content: `${data.prismicProject.data.image.url}`,
          },
          {
            property: "og:url",
            content: `https://danfinkstudio.com${data.prismicProject.url}`,
          },
          {
            property: "twitter:url",
            content: `https://danfinkstudio.com${data.prismicProject.url}`,
          },
        ]}
      />

      {isDesktop ? (
        <ProjectDesktop
          data={data}
          pageContext={pageContext}
          location={location}
          password={password}
          setPassword={setPassword}
          userPassword={userPassword}
          setUserPassword={setUserPassword}
          isPasswordCorrect={isPasswordCorrect}
          setIsPasswordCorrect={setIsPasswordCorrect}
          isPasswordProtected={isPasswordProtected}
          checkPassword={checkPassword}
        />
      ) : (
        <ProjectMobile
          data={data}
          pageContext={pageContext}
          location={location}
          password={password}
          setPassword={setPassword}
          userPassword={userPassword}
          setUserPassword={setUserPassword}
          isPasswordCorrect={isPasswordCorrect}
          setIsPasswordCorrect={setIsPasswordCorrect}
          isPasswordProtected={isPasswordProtected}
          checkPassword={checkPassword}
        />
      )}
    </>
  );
};

export default withPreview(Project);

export const query = graphql`
  query Project($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      _previewable
      url
      data {
        title {
          html
          text
        }
        tagline {
          html
          text
        }
        image {
          url
        }
        top_mobile_image {
          fluid {
            srcWebp
            srcSetWebp
          }
          alt
          dimensions {
            height
            width
          }
        }
        body {
          ... on PrismicProjectBodyTitle {
            id
            slice_type
            primary {
              title_slide {
                html
              }
            }
          }
          ... on PrismicProjectBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicProjectBodySingleImage {
            id
            slice_type
            primary {
              image {
                fluid(imgixParams: { ar: "1310:920", fit: "crop" }) {
                  srcWebp
                  srcSetWebp
                }
                alt
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicProjectBodyTwoImages {
            id
            slice_type
            primary {
              small_image
              image_left {
                alt
                fluid(imgixParams: { ar: "680:920", fit: "crop" }) {
                  srcWebp
                  srcSetWebp
                }
                dimensions {
                  height
                  width
                }
              }
              image_left_caption {
                html
              }
              image_right {
                alt
                dimensions {
                  height
                  width
                }
                fluid(imgixParams: { ar: "680:920", fit: "crop" }) {
                  srcWebp
                  srcSetWebp
                }
              }
              image_right_caption {
                html
              }
            }
          }
        }
        enable_password_protected_content
        password
        body1 {
          ... on PrismicProjectBody1Title {
            id
            slice_type
            primary {
              title_slide {
                html
              }
            }
          }
          ... on PrismicProjectBody1Text {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicProjectBody1SingleImage {
            id
            slice_type
            primary {
              image {
                fluid(imgixParams: { ar: "1310:920", fit: "crop" }) {
                  srcWebp
                  srcSetWebp
                }
                alt
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicProjectBody1TwoImages {
            id
            slice_type
            primary {
              small_image
              image_left {
                alt
                fluid(imgixParams: { ar: "680:920", fit: "crop" }) {
                  srcWebp
                  srcSetWebp
                }
                dimensions {
                  height
                  width
                }
              }
              image_left_caption {
                html
              }
              image_right {
                alt
                dimensions {
                  height
                  width
                }
                fluid(imgixParams: { ar: "680:920", fit: "crop" }) {
                  srcWebp
                  srcSetWebp
                }
              }
              image_right_caption {
                html
              }
            }
          }
        }
      }
    }
  }
`;
